#receiptsPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    background-color: #8282be;
}

.navigations {
    flex: 1;
    display: flex;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.navigation {
    cursor: pointer;
    color: #ffffff;
    flex-basis: 25%;
    text-align: center;
    margin: 0 20px;
    padding: 5px 0;
}

.activeNavigation {
    border-bottom: 1px solid #ffffff;
}

.body {
    height: 100%;
}

.btns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 20px 10px;
    overflow: hidden;
    border-bottom: 1px solid #efefef;
}

.btn {
    flex: 1;
    margin: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn p {
    margin: 0 0 5px 0;
    text-align: center;
    font-size: 12px;
}

.content {
    height: 80px;
    font-weight: 900;
    width: 80px;
    border-radius: 2px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:nth-child(1) .content {
    background-color: #9b9b9b;
}

.btn:nth-child(2) .content {
    background-color: #cc3232;
}

.btn:nth-child(3) .content {
    background-color: #38b88c;
}

.loading {
    height: 75px;
    overflow: hidden;
}