#couponPage {
    min-height: 100%;
    background-color: #ffffff;
    padding-bottom: 50px;
}

.body, .header, .details, .howTo {
    padding: 0 10px;
}

.header {
    padding: 10px 16px;
    border-bottom: 6px solid #f2f2f2;
}

.image {
    position: relative;
    height: 40%;
}

.image img {
    width: 100%;
    height: auto;
    display: block;
}

.priceHeader {
    display: flex;
    justify-content: space-between;
}

.name {
    color: #858585;
    padding: 10px 0;
}

.conditions {
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
    font-weight: bold;
}

.conditions div {
    margin: 0 2px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #cccccc;
}

.details p, .howTo p {
    line-height: 26px;
    margin: 0;
    font-weight: 500px;
    color: #858585;
}

.howTo {
    padding-bottom: 40px;
}

.details {
    padding-bottom: 40px;
    border-bottom: 3px solid #f2f2f2;
}

h3 {
    font-weight: 500;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loader {
    animation: spinner 1s linear infinite;
}

@media screen and (min-width: 450px) {
    .conditions {
        font-size: 14px;
    }
}