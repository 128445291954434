.images {
    height: 100%;
    position: relative;
    background-image: url("../../assets/img/backdrop.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@keyframes slide {
    from {
        bottom: -200%;
    }
    to {
        bottom: 0;
    }
}

.receipt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -200%;
    margin: auto;
    height:30%;
    width: auto;
    animation-duration: 0.8s;
    animation-delay: 0.5s;
    animation-name: slide;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

}