.tips{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.steps{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    color: #4db5db;
    font-weight: 500;
    font-size: 16px;
}

.dots{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot{
    width: 10px;
    height: 10px;
    background-color: #4db5db;
    opacity: 0.5;
    border-radius: 10px;
    margin: 0 5px;
    transition: all 0.1s linear;
}

.dot.active{
    opacity: 1;
    width: 14px;
    height: 14px;
    transition: all 0.1s linear;
}

.file{
    opacity: 0;
}