.errorPage {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #4db5db;
    font-size: 20px;
    text-align: center;
}

.title {
    font-weight: 900;
    font-size: 28px;
    margin: 20px 10px;
}

.message {
    font-size: 18px;
    margin: 20px 10px;
}

.logo {
    width: 30%;
    margin: 50px;
}

.body {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}