.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "~react-image-gallery/styles/css/image-gallery.css";
.image-gallery * {
  outline: none;
}

.image-gallery-slide .image-gallery-image {
  height: 100%;
  max-height: 100%;
}

.image-gallery, .image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide, .image-gallery-slide>div {
  height: 100%;
}

.image-gallery-bullets .image-gallery-bullet {
  border-color: #8282be;
  box-shadow: none;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: #8282be;
}

.react-tabs__tab-list {
  display: flex;
  border: none;
  border-bottom: none !important;
  justify-content: space-around;
}

.react-tabs__tab-list li {
  border: none;
}

.react-tabs__tab {
  flex: 1 1;
  text-align: center;
  margin: 4px 16px;
  padding: 10px 0 !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

li.react-tabs__tab--selected{
  border-bottom: 2px solid #4db5db;
}

a, .link {
  text-decoration: underline;
  display: inline;
}