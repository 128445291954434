.container{
    padding: 15px 10px;
}

.title{
    color: #4db5db;
    font-weight: 900;
}

.receipts{

}

.receipt{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
}

.data{
    flex: 1;
}

.data p{
    margin: 0;
}

.data p:nth-child(1){
}

.data p:nth-child(2){
    color:#9b9b9b;
    font-size: 12px;
}