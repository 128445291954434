.count {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 10px;
    padding: 2px 8px;
    background-color: #f25a24;
    color: #ffffff;
    z-index: 9090;
}

@media screen and (min-width: 450px) {
    .count {
        font-size: 14px;
        padding: 5px 10px;
    }
}