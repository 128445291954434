.receiptPage{
    height: 100%;
    width: 100%;
}

.image {
    height: 30%;
}

.image img{
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
}

.body{
}

.tag{
    padding: 6px 15px;
}

.tag:last-child{
    border-bottom: 1px solid #D3D3D3;
}

.tagValue{
    font-size: 14px;
}

.tagName{
    color:#9b9b9b;
    font-size: 12px;
}

h3{
    padding: 0 15px;
    color: #4db5db;
    font-size: 16px;
    font-weight: 500;
}

.action{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{
    background-color: #4db5db;
    margin: 10px 20px;
    margin-top: 30px;
    padding: 10px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    box-shadow: 0px 1px 1px 0px #2f333a;
    outline: none;
    width: 100%;
}