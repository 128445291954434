.images {
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.tips{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.tips div{
    padding: 5px;
    width: 45%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    flex-direction: column;
    text-align: center;
    font-weight: normal;
    color: #858585;
}

.tips div img{
    width: 60px;
    height: 60px;
}

.tips div p{
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 12px;
    margin: 0;
}