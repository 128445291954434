.snap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.receipts {
    height: calc(100% - 50px);
}

.receipts div {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.receipts div img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    image-orientation: none;
}

.receipts div::before {
    padding: 5px;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: #4db5db;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 500;
    content: attr(data-index);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
}

.multiple div:first-child {
    height: 15%;
    width: 100%;
    position: relative;
}

.multiple div:first-child img {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    max-height: auto;
    height: auto;
    max-height: max-content;
}

.multiple div:nth-child(2) {
    height: 85%;
    width: 100%;
}

.actions {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
}

.plus {
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #4db5db;
    padding: 5px;
    border-radius: 40px;
    box-shadow: 0px 1px 2px 1px rgba(133, 133, 133, 0.3);
}