.images {
    height: 100%;
    position: relative;
    background-image: url("../../assets/img/backdrop_light.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.receipt, .mobile {
    position: absolute;
    top: 0;
    bottom: -200%;
    left: 0;
    right: 0;
    margin: auto;
    animation-name: slide;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slide {
    from {
        bottom: -200%;
    }
    to {
        bottom: 0;
    }
}

.mobile {
    height: 50%;
    width: auto;
    animation-duration: 0.8s;
    animation-delay: 1.3s;
}

.receipt {
    height: 70%;
    width: auto;
    animation-duration: 0.8s;
    animation-delay: 0.5s;
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.plus{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25%;
    background-color: #4db5db;
    border-radius: 50px;
    border: 5px solid #4db5db;
    height: 35px;
    width: 35px;
    padding: 5px;
    opacity: 0;
    animation-name: show;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-delay: 2.1s;
    animation-fill-mode: forwards;
}