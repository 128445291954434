.expiry {
    color: #858585;
    padding: 10px 0;
    padding-top: 0;
    font-weight: 500;
    font-size: 10px;
}

@media screen and (min-width: 450px) {
    .expiry{
        font-size: 14px;
    }
}