.hot{
    color: #ffffff;
    background-color: red;
    transform: rotate(35deg);
    position: absolute;
    bottom: 5px;
    left: -14px;
    font-size: 10px;
    padding: 2px 10px;
    font-weight: 500;
    width: 45px;
    text-align: center;
}

@media screen and (min-width: 450px) {
    .hot{
        font-size: 14px;
        width: 65px;
    }
}