#couponsPage {
    width: 100%;
    height: 100%;
}

.sticky {
    height: 100%;
    overflow-y: auto;
}

.header {
    background-color: #8282be;
}

.navigations {
    flex: 1;
    display: flex;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.navigation {
    cursor: pointer;
    color: #ffffff;
    flex-basis: 25%;
    text-align: center;
    margin: 0 20px;
    padding: 5px 0;
}

.activeNavigation {
    border-bottom: 1px solid #ffffff;
}

.headerBg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../../assets/img/coupon.png");
}

.headerBg img{
    width: 35%;
    height: auto;
}

.traction {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.traction p {
    text-align: center;
    width: 75%;
}

.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f2f2f2;
}

.swiper {
    flex: 1;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 10px 5px;
    min-height: 34px;
}

.stickyContainer{
    z-index: 9999;
    background-color: #f2f2f2;
    top:0;
    right:0;
    left:0;
    position: fixed;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.52);
}

.btn {
    cursor: pointer;
    height: 14px;
    border-radius: 5px;
    color: #8d8d8d;
    letter-spacing: 0.3px;
    font-size: 12px;
    background-color: #ffffff;
    padding: 10px 0;
    font-weight: 900;
    margin: 2px;
    text-align: center;
    flex: 1;
    margin: 0 5px;
}

.activeBtn {
    background-color: #8283be;
    color: #ffffff;
}

.coupons {
    display: grid;
    grid-template-columns: repeat(auto-fill, 156px);
    justify-content: center;
    padding-bottom: 20px;
    overflow: visible;
}

@media screen and (min-width: 350px) {
    .coupons {
        grid-template-columns: repeat(auto-fill, 170px);
    }
}

@media screen and (min-width: 375px) {
    .coupons {
        grid-template-columns: repeat(auto-fill, 175px);
    }
}

@media screen and (min-width: 450px) {
    .coupons {
        grid-template-columns: repeat(auto-fill, 200px);
    }
}

@media screen and (min-width: 500px) {
    .coupons {
        grid-template-columns: repeat(auto-fill, 250px);
    }
}

.coupons::after {
    content: '';
    flex: auto;
}

.empty {
    padding: 30px 20px;
    text-align: center;
    min-height: 380px;
}

.emptyImg {
    width: 100%;
    height: auto;
}

.emptyMessage {
    color: #828282
}