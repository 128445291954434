.reward{

}

.value{
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
}

.quantity{
    padding-top: 8px;
    padding-bottom: 5px;
    font-size: 10px;
}

.type{
    font-size: 12px;
}

.small .value {
    flex: 1;
    font-weight: 500;
    font-size: 16px;
}

.small .quantity {
    font-size: 8px;
    color: #858585;
}

.small .type {
    font-size: 10px;
}

.small .currency{
    width: 15px;
    height: 15px;
}

.currency{
    width:18px;
    height:18px;
    margin-right: 2px;
}

@media screen and (min-width: 450px) {
    .value{
        font-size: 24px;
    }
    
    .quantity{
        font-size: 14px;
    }
    
    .type{
        font-size: 16px;
    }
    
    .small .value {
        font-size: 16px;
    }
    
    .small .quantity {
        font-size: 12px;
    }
    
    .small .type {
        font-size: 14px;
    }
    
    .small .currency{
        width: 20px;
        height: 20px;
    }
    
    .currency{
        width:25px;
        height:25px;
        margin-right: 2px;
    }
}