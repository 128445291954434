.expiry {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(248,143,142, 0.7);
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.3px;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expiry svg{
    padding: 0 5px;
}