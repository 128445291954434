.images {
    height: 100%;
    position: relative;
    background-image: url("../../assets/img/backdrop_light.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.receipt, .mobile {
    position: absolute;
    top: 0;
    bottom: -200%;
    left: 0;
    right: 0;
    margin: auto;
    animation-name: slide;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    z-index:200;
}

@keyframes slide {
    from {
        bottom: -200%;
    }
    to {
        bottom: 0;
    }
}

.mobile {
    height: 50%;
    width: auto;
    animation-duration: 0.8s;
    animation-delay: 1.8s;
}

.receipt {
    top:-5%;
    height: 32%;
    width: auto;
    animation-duration: 0.8s;
    animation-delay: 1s;
}

@keyframes showPlus {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.wrong, .right{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50px;
    opacity: 0;
    height: 35px;
    width: 35px;
    padding: 5px;
    animation-name: showPlus;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-delay: 2.6s;
    animation-fill-mode: forwards;
    z-index: 300;
}
.wrong{
    right: 10%;
    background-color: #cb3232;
    border-radius: 50px;
}

.right{
    left: 10%;
    background-color: #4db5db;
    border-radius: 50px;
}

@keyframes showOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

.overlay{
    opacity: 0;
    height: 100%;
    width: 50%;
    right: 0;
    position: absolute;
    top: 0;
    background-color: black;
    z-index: 200;
    animation-name: showOverlay;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}