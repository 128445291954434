.header{
    color: #ffffff;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.layout{
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #8282be;
}

.title{
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    padding-left: 20px;
}

.children{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #ffffff;
}

.children.scrolling{
    overflow-y: auto;
}

.headerButton{
    margin: 5px;
}