.section{
    padding: 18px;
}

h3{
    color: #217bdf;
    font-size: 16px;
    font-weight: normal;
}

.section > p{
    color: #9b9b9b;
    font-size: 12px;
}

.icons{
    display: flex;
    flex-wrap: wrap;
}

.icon{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 8px;
}

.icon img{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    filter: grayscale(100%);
    border: 2px solid transparent;
}

.active img{
    filter: initial;
    border-color: #217bdf;
}

.icon p {
    font-size: 12px;
    width: 75px;
    text-align: center;
}

.loading{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loadingMessage{
    margin-top: 80px;
}

.btn{
    background-color: #4db5db;
    margin: 10px 20px;
    padding: 10px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    box-shadow: 0px 1px 1px 0px #2f333a;
    outline: none;
}

.disabled{
    background-color: #9e9e9e;
    color: black;
}

.action{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

