.tip {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index:1000;
    overflow: hidden;
}

.tip h3{
    margin: 12px 0;
}

.imageContainer {
    height: 70%;
}

.tips {
    padding: 5px;
    text-align: center;
    height: 30%;
    background-color: #ffffff;
    z-index: 1000;
}

.secondaryTip{
    margin: 0;
    font-size: 14px;
}