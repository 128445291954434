.fileContainer{
    position: relative;
}

.file{
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    height: 100%;
    width: 100%;
    opacity: 0;
}
