.submittedPage {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #4db5db;
    font-size: 26px;
    padding: 50px 20px;
    text-align: center;
    padding-bottom: 0;
}

.error .title{
    color: #cc3232;
}

.message {
    margin-top: 30px;
    font-size: 16px;
    padding: 5px 30px;
    text-align: center;
}

.icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    background-color: #4db5db;
    margin: 10px 20px;
    margin-top: 30px;
    padding: 10px 25px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    box-shadow: 0px 1px 1px 0px #2f333a;
    outline: none;
    margin: 20px;
}