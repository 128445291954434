.modal {
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    background: white;
    height: auto;
    width: 275px;
    position: fixed;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    outline: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.title{
    margin: 8px;
    font-weight: 500;
}

.message {
    font-size: 16px;
    margin: 8px;
}

.btn {
    background-color: #4db5db;
    padding: 6px 15px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    box-shadow: 0px 1px 1px 0px #2f333a;
    outline: none;
    align-self: flex-end;
    margin: 8px;
}