.floatingButton {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
}

.floatingButton img {
    width: 100%;
    height: 100%;
}